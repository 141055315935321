import React, { useState, useEffect } from 'react';
import { useQueryParam, StringParam } from "use-query-params";

import { EP, callApi } from '../../api';
import TransitionAlert from '../../components/transition-alert';
import Loading from '../../components/loading';
import useAuth from '../../components/use-auth';
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import TenantForm from '../../components/tenant-form';

const EditTenantPage = ({path}) => {
  const auth = useAuth();
  const [id] = useQueryParam('id', StringParam);
  const { tenant, isLoading, isError } = useFetchTenant(auth, id);
  return (
    <Layout auth={auth} path={path}>
      <SEO title="Edit tenant" />
      { isLoading && <Loading /> }
      { isError && (
        <TransitionAlert severity="error" isOpen>
          There was an error fetching the tenant.
        </TransitionAlert>
      )}
      { tenant && (
        <TenantForm action="edit" tenant={tenant} />
      )}
    </Layout>
  )
}

const useFetchTenant = (auth, tenantId) => {
  const [tenant, setTenant] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    async function fetchData() {
      try {
        setIsLoading(true);
        const tenant = await callApi(EP.tenants.get, { tenantId });
        setIsLoading(false);
        setTenant(tenant);
      } catch (e) {
        console.log(e);
        setIsLoading(false);
        setIsError(true);
      }
    }
    fetchData();
  }, [auth]);
  
  return { tenant, isLoading, isError };
}

export default EditTenantPage;
